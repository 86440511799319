import Vue from "vue";
import { GridPlugin, Sort, Edit, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import CustomerEditTemplate from "@/components/Templates/Customer/CustomerEditTemplate.vue";
import { mapGetters } from "vuex";
import { getDropDownValue } from "@/helpers/dropDownHelper";
import CnsCardReader from "@/mixins/cnsCardReader";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin, CnsCardReader],
    data() {
        return {
            title: this.$t("customers.create.title"),
            editSettings: {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                mode: "Dialog",
                template: function () {
                    return {
                        template: Vue.component("customer-service-item", CustomerEditTemplate)
                    };
                }
            },
            commands: [
                /*
                {
                  type: "Edit",
                  buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                */
                {
                    type: "Delete",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-delete e-icons"
                    }
                }
            ],
            timeSlots: [],
            acquisitionChannels: [],
            services: [],
            customer: {
                name: "",
                surname: "",
                services: []
            },
            toolbar: ["Add"],
            sortOptions: {
                columns: [
                    { field: "officeName", direction: "Ascending" },
                    { field: "serviceName", direction: "Ascending" }
                ]
            },
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        maxLength: 100
                    },
                    surname: {
                        required: true,
                        maxLength: 100
                    },
                    fiscalCode: {
                        maxLength: 16
                    },
                    phoneNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    mobileNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    email: {
                        email: true,
                        maxLength: 100
                    }
                }
            },
            serviceValidationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    serviceId: {
                        required: true
                    },
                    price: {
                        required: true,
                        number: true,
                        min: 0
                    }
                }
            }
        };
    },
    provide: {
        grid: [Sort, Edit, Toolbar, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        create() {
            return this.$store.dispatch("customer/create", {
                customer: this.customer
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("customers.create.toastSuccessTitle"), this.$t("customers.create.toastSuccessContent", {
                name: `${this.customer.name} ${this.customer.surname}`
            }));
            return this.$router.push("/customers");
        },
        cancel() {
            return this.$router.push("/customers");
        },
        actionBegin(args) {
            if (args.requestType === "save") {
                args.data["officeName"] = args.form.querySelector("#officeId").value;
                args.data["serviceName"] = args.form.querySelector("#serviceId").value;
            }
        },
        actionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const customerServices = this.customer.services;
                args.form.ej2_instances[0].addRules("serviceId", {
                    exists: [
                        function (args) {
                            const officeIdOld = getDropDownValue(args.element.form, "#officeIdOld");
                            const officeId = getDropDownValue(args.element.form, "#officeId");
                            const serviceIdOld = getDropDownValue(args.element.form, "#serviceIdOld");
                            if (args.serviceId == officeIdOld && args.value == serviceIdOld)
                                return true;
                            return !customerServices.some(service => service.officeId == officeId && service.serviceId == args.value);
                        },
                        this.$t("customers.serviceExists")
                    ]
                });
            }
            if (args.requestType === "add") {
                args.dialog.width = 380;
                args.dialog.header = this.$t("customers.newService");
            }
            else {
                args.dialog.width = 380;
                args.dialog.header = this.$t("customers.editService", {
                    name: args.rowData["serviceName"]
                });
            }
        },
        setData(data) {
            if (data == null) {
                this.$toast.showWarningToast(this.$t("csnCardReader.toastWarningTitle"), this.$t("csnCardReader.toastWarningContent"));
                return;
            }
            this.customer.name = data.firstName;
            this.customer.surname = data.lastName;
            this.customer.fiscalCode = data.ssn;
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.acquisitionChannels = await this.$store.dispatch("acquisitionChannel/getAll");
            this.acquisitionChannels.unshift({
                id: undefined,
                name: this.$t("acquisitionChannels.none"),
                description: ""
            });
            this.timeSlots = await this.$store.dispatch("customerTimeSlot/getAll");
            this.timeSlots.unshift({
                id: undefined,
                name: this.$t("customersTimeSlots.none"),
                startTime: new Date(),
                endTime: new Date(),
                order: -1
            });
            this.services = await this.$store.dispatch("service/getAll", {
                load: true
            });
            this.customer.services = [];
            this.hideSpinner();
            document.getElementById("card-reader").focus();
            this.$on("cnsDataRead", this.setData);
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/customers");
        }
    },
    beforeDestroy() {
        this.$off("cnsDataRead", this.setData);
    }
});
