import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            eventsCount: 0,
            timeoutHandler: undefined
        };
    },
    mounted() {
        const inputElements = document.querySelectorAll("input");
        inputElements.forEach(element => element.removeEventListener("keydown", this.onKeyDown));
        inputElements.forEach(element => element.addEventListener("keydown", this.onKeyDown));
    },
    beforeDestroy() {
        const inputElements = document.querySelectorAll("input");
        inputElements.forEach(element => element.removeEventListener("keydown", this.onKeyDown));
    },
    methods: {
        onKeyDown(e) {
            if (this.timeoutHandler != undefined)
                clearTimeout(this.timeoutHandler);
            this.eventsCount++;
            this.timeoutHandler = setTimeout(() => {
                if (this.eventsCount >= 16 && e.target != null) {
                    const targetInput = e.target;
                    const data = this.parseInput(targetInput.value);
                    targetInput.value = "";
                    this.$emit("cnsDataRead", data);
                }
                this.eventsCount = 0;
            }, 40);
            if (e.code == "Enter" && this.eventsCount >= 16) {
                e.preventDefault();
            }
        },
        parseInput(value) {
            try {
                const regExp = /%(?<ssn>[a-zA-Z0-9]{16})(?<lastName>[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\s]+)\s\s(?<firstName>[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\s]+)(_|\?)/g;
                const match = regExp.exec(value);
                if (match == null || match.groups == null)
                    return null;
                return {
                    ssn: match.groups["ssn"],
                    lastName: match.groups["lastName"],
                    firstName: match.groups["firstName"],
                };
            }
            catch (e) {
                console.log(e);
                return null;
            }
        }
    }
});
