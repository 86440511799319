export function getDropDownValue(element, selector) {
    if (selector != undefined)
        element = element?.querySelector(selector);
    if (element == undefined)
        return undefined;
    const dropDownInstances = element.ej2_instances;
    if (dropDownInstances == undefined || dropDownInstances.length === 0)
        return undefined;
    const dropDownInstance = dropDownInstances[0];
    return dropDownInstance?.value;
}
